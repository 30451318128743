import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const CreateTestCase = () => {
  return (
    <LayoutComponent>
      <h2>Create a Test Case</h2>
      <p>
        This guide provides step-by-step instructions on how to create a test
        case, along with important considerations during the process.
      </p>

      <ol>
        <li>
          On the Test Cases main page, click <strong>+New Test Case</strong> to
          start creating a new test case.
        </li>
        <li>
          The <strong>Create Test Case</strong> modal will appear. Complete the
          required details for your test case.
        </li>
        <div className="testimonials">
          <h5>Note</h5>
          <p>
            The <strong>Status</strong> field indicates whether the test case is
            ready for planning and execution, but it does not show the results
            of the test execution.
          </p>
        </div>
        <li>
          Once you've filled in the details, click <strong>Create</strong>. The
          system will automatically redirect you to the{" "}
          <strong>Test Steps</strong> tab on the single test case page to define
          the test steps.
        </li>
        <li>
          In the <strong>Test Steps</strong> tab, enter the Steps, Test Data,
          and Expected Results required to execute the test case.
        </li>
        <div className="testimonials">
          <h5>Note</h5>
          <p>
            You can edit or delete steps after they have been added, if
            necessary.
          </p>
        </div>
      </ol>
      <h5>Additional Information:</h5>
      <ul>
        <li>
          In the <strong>Traceability</strong> tab, use the{" "}
          <strong>Search Issues </strong> option to associate an existing Jira
          issue with the test case, or click <strong> +</strong> to create and
          link a new issue. You can also link web URLs here.
        </li>
        <li>
          The <strong>Execution</strong> tab allows you to track the results of
          previous test executions linked to the test case.
        </li>
        <li>
          Use the <strong>Attachments</strong> tab to upload relevant files to
          the test case for reference.
        </li>
        <li>
          The <strong>Comments</strong> tab is where you can add notes or
          comments related to the test case.
        </li>
      </ul>
    </LayoutComponent>
  )
}

export default CreateTestCase
